import { Browser } from "@capacitor/browser";
import { Text, Title } from "@clipboard-health/ui-react";
import { Button, CardActions, CardContent, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { AFFILIATE_GUIDE_LINK, REFERRAL_GUIDE_LINK } from "../constants";
import { useWorkerToWorkplaceAffiliateExperiment } from "../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../types";
import { getWorkerToWorkplaceVariantName } from "../utils/getWorkerToWorkplaceVariantName";
import { WorkplaceReferralCard } from "./WorkplaceReferralCard/WorkplaceReferralCard";

interface ReferralGuideProps {
  referralCode: string;
}

export function ReferralGuide(props: ReferralGuideProps) {
  const { referralCode } = props;
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  return (
    <WorkplaceReferralCard>
      <CardContent>
        <Stack spacing={2}>
          {isWorkerToWorkplaceAffiliateProgramEnabled ? (
            <>
              <Title bold component="h2" variant="h4">
                Affiliate Tips & Tricks
              </Title>
              <Text>
                This guide will offer you additional tips for how to make successful referrals to
                workplaces.
              </Text>
            </>
          ) : (
            <>
              <Title bold component="h2" variant="h4">
                Referrals Guide
              </Title>
              <Text>
                This guide gives you everything you need to start successfully referring workplaces.
              </Text>
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant={isWorkerToWorkplaceAffiliateProgramEnabled ? "outlined" : "contained"}
          size="small"
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
              action: WorkplaceReferralAction.REFERRAL_GUIDE_CLICKED,
              component: WorkplaceReferralComponent.REFERRAL_GUIDE_SECTION,
              variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
              referralCode,
            });
            await Browser.open({
              url: isWorkerToWorkplaceAffiliateProgramEnabled
                ? AFFILIATE_GUIDE_LINK
                : REFERRAL_GUIDE_LINK,
            });
          }}
        >
          {isWorkerToWorkplaceAffiliateProgramEnabled
            ? "Access Your Affiliate Guide"
            : "Access the Referrals Guide"}
        </Button>
      </CardActions>
    </WorkplaceReferralCard>
  );
}
