export const ACCOUNT_ROUTE = "/home/account";
export const REFERRAL_POLICY_LINK =
  "https://support.clipboardhealth.com/hc/en-us/articles/18887245879959";
export const REFERRAL_GUIDE_LINK =
  "https://support.clipboardhealth.com/hc/en-us/articles/18885669493655";
export const AFFILIATE_GUIDE_LINK =
  "https://support.clipboardhealth.com/hc/en-us/articles/19842352207767";
export const REFERRAL_SHIFT_ATTENDANCE_RATE =
  "https://support.clipboardhealth.com/hc/en-us/articles/18915769949847";

export const defaultWorkerToWorkplaceIncentive = {
  "Long Term Care": 100,
  Hospital: 100,
  School: 100,
  "Dental Clinic": 75,
  Pharmacy: 75,
};

export const WORKPLACE_REFERRAL_STATS_SCREEN_NAME = "Workplace Referral Stats";
export const WORKPLACE_AFFILIATE_STATS_SCREEN_NAME = "Affiliate Program Stats";
export const WORKPLACE_REFERRAL_SCREEN_NAME = "Refer Workplaces";
export const WORKPLACE_AFFILIATE_SCREEN_NAME = "Workplace Affiliate Program";
export const bonusBreakdownExpandedLocalStorageKey = "bonusBreakdownExpanded";
